<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>CTT</h1>
    <div class="container">
      <h3>Lista de Envios Pendentes</h3>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">DATA</th>
            <th scope="col">Transportadora</th>
            <th scope="col">Encomenda</th>
            <th scope="col">Nº Envio</th>
            <th scope="col">
              Confirmar <br />
              <input type="checkbox" @change="checkedAll($event)" />
            </th>
            <th scope="col">Cancelar</th>
          </tr>
        </thead>
        <tbody v-for="shipment in cttShipments" :key="shipment.id">
          <td>{{ shipment.date }}</td>
          <td>{{ shipment.shipping_company }}</td>
          <td>{{ shipment.order_id }}</td>
          <td>
            <label :for="shipment.id">{{ shipment.tracking_number }}</label>
          </td>
          <td>
            <input
              type="checkbox"
              :id="shipment.id"
              :value="{
                id: shipment.id,
                order_id: shipment.order_id,
                tracking: shipment.tracking_number,
              }"
              v-model="checked"
            />
          </td>
          <td @click="deleteShipment(shipment.id, shipment.tracking_number)">
            ❌
          </td>
        </tbody>
      </table>
    </div>
    <h6><b>Total Encomendas: </b> {{ checked.length }}</h6>

    <div>
      <button
        class="btn btn-primary"
        @click="cttDataClose()"
        :disabled="checked.length == 0"
      >
        Fechar Guia CTT
      </button>
    </div>
    <br />
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Transportadora</th>
          <th scope="col">Data</th>
          <th scope="col">Ficheiro</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="close in allShipments" :key="close.id">
          <td>{{ close.shipping_company }}</td>
          <td>{{ calculateDate(close.date) }}</td>
          <td>
            <button class="btn btn-primary" @click="openFile(close.file)">
              Ver Ficheiro
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <b-modal
      ref="my-modal"
      id="my-modal"
      title="Lista de Encomendas para Fecho"
      size="xl"
      hide-footer
    >
      <b-table
        striped
        hover
        :items="cttOrdersClose"
        :fields="fields"
        thead-class="text-blue"
        class="table-orders"
      >
        <template #cell(name)="data">
          {{ data.item.first_name }} {{ data.item.second_name }}
        </template>
      </b-table>
      <div>
        <button class="btn btn-primary btn-sm" v-b-modal.my-validation>
          Validar
        </button>
      </div>
    </b-modal>
    <b-modal
      ref="my-validation"
      id="my-validation"
      title="Dados da Guia CTT"
      size="sm"
      hide-footer
    >
      <form v-on:submit.prevent="cttClose()">
        <div class="form-group">
          <label for="numberInvoice">Nº Identificador de Guia</label>
          <input
            type="text"
            class="form-control"
            id="numberInvoice"
            v-model="numberInvoice"
            placeholder="Nº Identificador de Guia"
            required
          />
        </div>
        <div>
          <button class="btn btn-primary" type="submit">Confirmar</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      allShipments: [],
      loading: false,
      cttShipments: [],
      checked: [],
      cttFile: [],
      cttData: [],
      cttOrdersClose: [],
      numberInvoice: "",
      fields: [
        {
          key: "id",
          label: "ID",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "adress1",
          label: "Morada",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "city",
          label: "Cidade",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "state",
          label: "Estado",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "postcode",
          label: "CP",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "country",
          label: "País",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "tracking_number",
          label: "Tracking",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "weight",
          label: "Peso",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "expedition_number",
          label: "Nº Envio",
          tdClass: "align-middle",

          thStyle:
            "font-size: 13px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    async cttDataClose() {
      await this.$store.dispatch("dataCttClose", {
        shipments: this.checked,
      });
      console.log(this.getcttDataClose);
      this.cttData = this.getcttDataClose;
      this.cttOrdersClose = this.getcttOrdersClose;
      this.$refs["my-modal"].show();
    },
    async cttClose() {
      this.loading = true;
      try {
        await this.$store.dispatch("getCTTFileToday", {
          shipments: this.checked,
          numberInvoice: this.numberInvoice,
        });
      } catch (err) {
        alert(err);
      }
      this.loading = false;
      this.cttFile = this.getCTTFileToday;
      window.open("data:application/pdf;base64," + this.cttFile, "_blank");
      this.$refs["my-validation"].hide();
      this.$refs["my-modal"].hide();
      await this.allCTTShipments();
      await this.getAllClose();
      this.checked = [];
    },
    async getAllClose() {
      this.loading = true;
      try {
        await this.$store.dispatch("allNCloseShipment");
        this.allShipments = this.getAllCTTClose;
      } catch (err) {
        alert(err);
      }
      this.loading = false;
    },
    openFile(file) {
      window.open("data:application/pdf;base64," + file, "_blank");
    },
    calculateDate(date) {
      let newDate = new Date(date.split("T"));
      let year = newDate.getFullYear();
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let day = ("0" + newDate.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    async allCTTShipments() {
      await this.$store.dispatch("allShipmentsCTT");
      this.cttShipments = this.getAllCTTShipments;
    },
    async deleteShipment(id, shipment) {
      this.$swal({
        title: "Quer eliminar o envio " + shipment + " ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eliminar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading = true;
          try {
            await this.$store.dispatch("cttDeleteShipment", {
              id: id,
            });
            await this.allCTTShipments();
            this.$swal("Completado", "Envio Eliminado!", "success");
          } catch (err) {
            alert(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async checkedAll($event) {
      this.checked = $event.target.checked
        ? this.cttShipments.map((shipment) => ({
            id: shipment.id,
            order_id: shipment.order_id,
            tracking: shipment.tracking_number,
          }))
        : [];
      console.log(this.checked);
    },
  },
  async created() {
    this.loading = true;
    await this.allCTTShipments();
    await this.getAllClose();
    this.loading = false;
  },
  computed: {
    ...mapGetters(["getAllCTTClose"]),
    ...mapGetters(["getAllCTTShipments"]),
    ...mapGetters(["getCTTFileToday"]),
    ...mapGetters(["getcttOrdersClose"]),
    ...mapGetters(["getcttDataClose"]),
  },
};
</script>
<style scoped>
* {
  text-align: center;
}
.table-orders {
  font-size: 10px;
}
</style>
